<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
      <en-button type="primary" @click="operation.add.click">编辑对接人</en-button>
    </template>
    <tabs-maintain
      v-model="tabs.status"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['MAPPSTAT']) }"
      :props="{ value: 'code', label: 'message' }"
      :method="table.get"
    ></tabs-maintain>
    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="600"
          code="SPMLAPNFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #COMPANY_NAME="{ row }: { row: EnosprayMallDefinitions['MallApplicationDto'] }">
            <en-button type="primary" text @click="table.companyName.click(row)">{{ row.companyName }}</en-button>
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>
  <en-drawer v-model="detail.visible" title="报名列表详情">
    <en-form :model="detail.form.data" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="企业名称" prop="companyName">
        <en-input v-model="detail.form.data.companyName" disabled></en-input>
      </en-form-item>
      <en-form-item label="联系人" prop="name">
        <en-input v-model="detail.form.data.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="联系人电话" prop="cellphone">
        <en-input v-model="detail.form.data.cellphone" disabled></en-input>
      </en-form-item>
      <en-form-item label="申请时间" prop="preparedDatetime">
        <en-date-picker v-model="detail.form.data.preparedDatetime" class="w-full" disabled></en-date-picker>
      </en-form-item>
      <en-form-item label="审核结果">
        <select-maintain
          v-model="detail.form.data.status"
          :options="[
            { message: '同意', code: 'A' },
            { message: '拒绝', code: 'R' }
          ]"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>

      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
  <en-drawer v-model="add.visible" title="编辑对接人">
    <en-form>
      <en-form-item label="对接人名称" v-if="add.table.data.find((item) => item.id.code === 'MALAPASB')">
        <en-input v-model="add.table.data.find((item) => item.id.code === 'MALAPASB').value"></en-input>
      </en-form-item>
      <en-form-item label="个人微信二维码" v-if="add.table.data.find((item) => item.id.code === 'MALAPASURL')">
        <upload-maintain v-model="add.table.data.find((item) => item.id.code === 'MALAPASURL').value" folder="application"></upload-maintain>
      </en-form-item>
   
    </en-form>
    <template #footer>
      <en-button @click="add.footer.cancel.click">取消</en-button>
      <button-ajax :method="add.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
type CustomerDto = EnosprayMallDefinitions['MallApplicationDto']
export default factory({
  props: {
    modelValue: {
      type: Object as PropType<CustomerDto>,
      require: true
    },
    loading: Boolean as PropType<boolean>,
    rules: Object
  },
  config: {
    children: {
      tabs: {
        status: ''
      },
      operation: {
        add: {
          click() {
            const item = this.add.table.data.map((item) => item.id?.code)

     
            this.add.visible = true
          }
        },
        export: {
          async click() {
            await this.table.export()
            await this.table.get()
            this.store.openDownload()
          }
        },
        edit: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      form: {
        data: { status: { code: '' } },
        async change<P extends keyof CustomerDto>(prop: P, value: any) {
          if (this.modelValue) this.modelValue[prop] = value
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/mall/application',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.form.data, ...this.tabs }
            }
          },
          export: {
            action: 'GET /enospray/mall/application/export',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          delete: {
            action: 'DELETE /enospray/common/auditor/:auditorId',
            loading: true
          }
        },
        config: {
          COMPANY_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'companyName'
              }
            }
          },
          CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'cellphone'
              }
            }
          },
          CATEGORY: {
            header: {
              filter: {
                type: 'text',
                field: 'categoryName'
              }
            }
          },
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SOURCE: {
            header: {
              filter: {
                type: 'select',
                field: 'source',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['MAPPSRC'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnosprayMallDefinitions['MallApplicationDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            }
          },
          companyName: {
            click(row: EnosprayMallDefinitions['MallApplicationDto']) {
              this.detail.form.init()
              this.detail.form.data = Object.assign({}, row)
              this.detail.visible = true
            }
          },
          add: {
            click(row: EnocloudAdminDefinitions['SystemAttributeDto']) {
              this.add.form.init()

              this.add.visible = true
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              user: {
                cellphone: ''
              }
            } as EnosprayMallDefinitions['MallApplicationDto'],
            ajax: {
              update: {
                action: 'PUT /enospray/mall/application',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            }
            // rules: { user: [{ required: true, message: '请输入人员姓名' }] }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form.update()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      },
      add: {
        visible: false,
        children: {
        
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/admin/branch/attribute',
                data: 'array',
                loading: true
              },
              update: {
                action: 'PUT /enocloud/admin/branch/attribute',
                loading: true,
                params(params) {
                  params.data = this.add.table.data
                },
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.add.visible = false
              }
            },
            confirm: {
              async click() {
                await this.add.table.update()
                return this.table.get().then(() => (this.add.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
    this.add.table.get()
  }
})
</script>
